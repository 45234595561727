import SwitchField from '../fields/SwitchField';
import { useCallback } from 'react';
import AddressField from '@/components/fields/AddressField.jsx';

const ApplicationWidgetCollateralObjectForm = ({ setData, data, errors, ...properties }) => {
    const handleFormChange = useCallback(
        (event) => {
            const { name, value } = event.target;

            setData((previousData) => ({ ...previousData, [name]: value }));
        },
        [setData],
    );

    const handleAddressChange = (event) => {
        const { value } = event.target;

        setData((previousData) => ({
            ...previousData,
            collateral_object: { ...previousData?.collateral_object, address: value },
        }));
    };
    return (
        <form {...properties}>
            <div className={'flex flex-col gap-4'}>
                <div className={'flex flex-col gap-2'}>
                    <AddressField value={data?.collateral_object?.address} onChange={handleAddressChange} />
                    <p className={'text-sm italic text-grey-900'}>Adressen worden pas gevonden zodra u een huisnummer opgeeft.</p>

                    {errors.hasOwnProperty('collateral_object') && <p className={'text-red-900'}>Adres is verplicht.</p>}
                </div>

                <div className={'flex flex-col gap-2'}>
                    <p>Bezit u meerdere vastgoed objecten?*</p>

                    <SwitchField name={'has_multiple_collateral_objects'} value={data?.has_multiple_collateral_objects} onChange={handleFormChange} />

                    {errors.hasOwnProperty('has_multiple_collateral_objects') && <p className={'text-red-900'}>{errors.has_multiple_collateral_objects}</p>}
                </div>
            </div>
        </form>
    );
};

export default ApplicationWidgetCollateralObjectForm;
