import Typography from '../Typography';
import { useCallback } from 'react';
import CurrencyField from '@/components/fields/CurrencyField';
import CompanyField from '@/components/fields/CompanyField.jsx';

const ApplicationWidgetCompanyForm = ({ data, setData, errors, ...properties }) => {
    const handleFormChange = useCallback(
        (event) => {
            const { name, value } = event.target;

            setData((previousData) => ({ ...previousData, [name]: value }));
        },
        [setData],
    );

    return (
        <form {...properties}>
            <div className={'flex flex-col gap-4'}>
                <div className={'flex flex-col gap-2'}>
                    <p>Vul uw bedrijfsnaam of kvk-nummer in</p>

                    <CompanyField name={'company'} value={data?.company} onChange={handleFormChange} />
                    <p className={'mt-2 text-sm italic text-grey-900'}>Staat u niet ingeschreven in de Kamer van Koophandel? Dan kunnen wij uw aanvraag helaas niet in behandeling nemen.</p>

                    {errors?.company && <Typography color={'error.base'}>{errors.company}</Typography>}
                </div>

                <div className={'flex flex-col gap-2'}>
                    <p>Omzet laatste boekjaar</p>

                    <CurrencyField name={'revenue_last_fiscal_year'} value={data?.revenue_last_fiscal_year} onChange={handleFormChange} />

                    {errors.hasOwnProperty('revenue_last_fiscal_year') && <p className={'text-red-900'}>{errors?.revenue_last_fiscal_year}</p>}
                </div>
            </div>
        </form>
    );
};

export default ApplicationWidgetCompanyForm;
