import React from 'react';
import ErrorMessage from '@/components/ErrorMessage.jsx';
import Checkbox from '@/components/Checkbox.jsx';

const ApplicationWidgetDeclarationForm = ({ data, setData, errors }) => {
    const handleCheckboxChange = (event) => {
        const { name, value } = event.target;

        setData((prevData) => ({ ...prevData, [name]: value }));
    };

    return (
        <div className='space-y-4'>
            <div className={'space-y-2'}>
                <p>Hierbij verklaart u alle gegevens naar waarheid te hebben ingevuld. Meer specifiek betekent dit dat:</p>

                <ul className='list-disc space-y-1'>
                    <li>
                        Indien u in de aanvraag heeft aangegeven dat u de rechthebbende bent van een onroerende zaak (waaronder bijvoorbeeld een koopwoning), dan heeft u verklaard dat deze onroerende zaak <strong>uw eigendom</strong> is. De onroerende zaak is dus uitdrukkelijk <strong>geen</strong>{' '}
                        eigendom van een derde (zoals bijvoorbeeld een verhuurder).
                    </li>

                    <li>U eigenaar of (vol)gemachtigde van de onderneming bent en u bevoegd bent om de onderneming in deze aanvraag rechtsgeldig te vertegenwoordigen;</li>

                    <li>
                        Het u onder geen enkel beding is toegestaan de lening aan te wenden voor andere dan zakelijke doeleinden, waaronder bijvoorbeeld consumptieve doeleinden. Capilex verstrekt enkel leningen aan zakelijke partijen en niet aan particuliere partijen. De lening zelf dient dan ook
                        uitdrukkelijk en uitsluitend een zakelijk doel te hebben.
                    </li>
                </ul>
            </div>

            <p>Vink hieronder aan dat u de bovenstaande voorwaarden heeft gelezen en dat u de aanvraag volledig naar waarheid heeft ingevuld. U kunt de aanvraag vervolgens aan Capilex verzenden.</p>

            <p>
                Indien later blijkt dat uw gegevens niet naar waarheid zijn ingevuld, dan behoudt Capilex zich het recht voor om kosten bij u in rekening te brengen een en ander zoals uitgewerkt in haar algemene voorwaarden. U kunt de algemene voorwaarden lezen door{' '}
                <a href='https://www.capilex.com/algemene-voowaarden' target={'_blank'} className='font-semibold text-primary-500 hover:underline' rel='noreferrer'>
                    hier te klikken
                </a>
                .
            </p>

            <div className='flex items-center space-x-2'>
                <Checkbox id='declaration' name='declaration_accepted' checked={data.declaration_accepted} onChange={handleCheckboxChange} label='Ik verklaar dat ik bovenstaande voorwaarden heb gelezen en ga hiermee akkoord' />
            </div>

            <ErrorMessage message={errors?.declaration_accepted} />
        </div>
    );
};

export default ApplicationWidgetDeclarationForm;
