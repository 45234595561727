import React from 'react';
import { motion } from 'framer-motion';
import Label from '@/components/Label.jsx';

const AnimatedCheckbox = ({ id, name, label, checked, onChange }) => {
    const handleChange = (event) => {
        onChange({
            target: {
                name: event.target.name,
                value: event.target.checked,
            },
        });
    };

    return (
        <div className='flex items-center space-x-2'>
            <motion.label htmlFor={id} className='relative flex size-5 cursor-pointer items-center justify-center rounded border border-grey-900' whileTap={{ scale: 0.9 }}>
                <input type='checkbox' id={id} name={name} checked={checked} onChange={handleChange} className='absolute z-10 size-full cursor-pointer opacity-0' />
                <motion.div className='absolute inset-0 rounded-sm bg-primary-500' initial={{ opacity: 0 }} animate={{ opacity: checked ? 1 : 0 }} transition={{ duration: 0.2 }} />
                <motion.svg className='pointer-events-none absolute inset-0 size-full' viewBox='0 0 24 24' initial={{ opacity: 0 }} animate={{ opacity: checked ? 1 : 0 }} transition={{ duration: 0.2 }}>
                    <motion.path d='M5 13l4 4L19 7' fill='none' stroke='currentColor' strokeWidth={3} strokeLinecap='round' strokeLinejoin='round' initial={{ pathLength: 0 }} animate={{ pathLength: checked ? 1 : 0 }} transition={{ duration: 0.2 }} />
                </motion.svg>
            </motion.label>

            <Label htmlFor={id} className='cursor-pointer'>
                {label}
            </Label>
        </div>
    );
};

export default AnimatedCheckbox;
